:root {
  --color-darkest: #343a40;
  --color-dark: #495057;
  --color-medium: #ced4da;
  --color-light: #f1f3f5;

  --color-theme: #1098ad;
  --color-accent: #ffa94d;
}
@import url("https://fonts.googleapis.com/css2?family=Codystar&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  min-height: 100vh;
  color: var(--color-light);
  background-color: var(--color-darkest);
  padding: 3.2rem;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;  
}


.main {
  width: 50rem;
}

.app-header {
  width: 66rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 2rem;
  background-color: #495057;
  border-radius: 100px;
}

img {
  width: 14rem;
}

h1 {
  font-family: "Codystar";
  font-size: 5.6rem;
}

h2 {
  font-size: 3.6rem;
  margin-bottom: 2rem;
}

h3 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 4rem;
}

h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
}

.start {  
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.progress {
  margin-bottom: 4rem;
  display: grid;
  justify-content: space-between;
  gap: 1.2rem;
  grid-template-columns: auto auto;
  font-size: 1.8rem;
  color: var(--color-medium);
}

progress {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  grid-column: 1 / -1;
}

::-webkit-progress-bar {
  background-color: var(--color-medium);
  border-radius: 100px;
}
::-webkit-progress-value {
  background-color: var(--color-theme);
  border-radius: 100px;
}

.btn {
  display: block;
  font-family: inherit;
  color: inherit;
  font-size: 2rem;
  border: 2px solid var(--color-dark);
  background-color: var(--color-dark);
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  border-radius: 100px;
  transition: 0.3s;
}

.btn:not([disabled]):hover {
  background-color: var(--color-darkest);
}

.btn-option:not([disabled]):hover {
  transform: translateX(1.2rem);
}

.btn[disabled]:hover {
  cursor: not-allowed;
}

.btn-ui {
  float: right;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 3.2rem;
}

.btn-option {
  width: 100%;
  text-align: left;
}

.btn-option.correct {
  background-color: var(--color-theme);
  border: 2px solid var(--color-theme);
  color: var(--color-light);
}
.btn-option.wrong {
  background-color: var(--color-accent);
  border: 2px solid var(--color-accent);
  color: var(--color-darkest);
}

.answer {
  transform: translateX(2rem);
}

.result {
  background-color: var(--color-theme);
  color: var(--color-light);
  border-radius: 100px;
  text-align: center;
  padding: 2rem 0;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
}

.result span {
  font-size: 2.2rem;
  margin-right: 4px;
}

.highscore {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 4.8rem;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  gap: 1.6rem;

  color: var(--color-medium);
  font-size: 1.4rem;
}

.timer {
  float: left;
  font-size: 1.8rem;
  color: var(--color-medium);
  border: 2px solid var(--color-dark);
  padding: 1.35rem 2.8rem;
  border-radius: 100px;
}

/* CREDIT: https://dev.to/afif/i-made-100-css-loaders-for-your-next-project-4eje */
.loader {
  width: 50px;
  height: 24px;
  background: radial-gradient(circle closest-side, currentColor 90%, #0000) 0%
      50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: loader 1s infinite linear;
}

@keyframes loader {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

/* ********** */
/* First counter example */
.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 6rem;
}

.counter * {
  font-size: inherit;
  padding: 0.8rem;
}

@media only screen and (max-width: 500px) {

    body {
        padding: 1.6rem;
    }

    .app{
      justify-content: center;  /* vertically centering children on mobile */
    }

    .app-header, 
    .main {
        width: 100%;
    }

    h1 {
        font-size: 4.5rem;
    }

    h2 {
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }

    h3 {
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }

    h4 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    .app-header {
        flex-direction: column;
        gap: 2rem;
    }

    .app-header h1 {
      font-size: 3rem;
    }

    img {
        width: 12rem;
        align-self: center;
    }

    .btn {
        font-size: 1.6rem;
        padding: 1rem 1.8rem;
    }

    .progress {
        grid-template-columns: 1fr 1fr;
        font-size: 1.6rem;
    }

    progress {
        margin-bottom: 1.2rem;
    }

    .timer {
        float: none;
        margin: 0 auto;
    }

    .btn-ui {
        float: none;
        margin-top: 1.6rem;
        display: block;
    }

    .counter {
        margin: 4rem 0;
    }

    .react-logo {
      width: 6rem; 
  }
  
  .start {
    padding: 2rem;
  }

  .start h2 {
    font-size: 2rem; 
  }
  .start h3 {
      font-size: 1.4rem; 
  }

}